// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import i18next from 'i18next';
import { setWorkbookDisplayMode } from '@/workbook/workbook.actions';
import { parseISODate, parseRelativeDate } from '@/datetime/dateTime.utilities';
import { createWorksheet, getWorksheets } from '@/worksheets/worksheets.utilities';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { API_TYPES, SEARCH_TYPES } from '@/main/app.constants';
import { sqFoldersApi, sqItemsApi, sqTreesApi, SwapOptionListV1 } from '@/sdk';
import { isTrendable, validateGuid } from '@/utilities/utilities';
import { PUSH_WORKSTEP_IMMEDIATE } from '@/core/flux.service';
import { ASSET_DELIMITER, BuilderParameters, DEFAULT_WORKBOOK_NAME, ViewMode } from '@/builder/builder.constants';
import { SEARCH_PER_PAGE } from '@/search/search.constants';
import {
  DEFAULT_DISPLAY_RANGE_DURATION_DAYS,
  DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS,
} from '@/trendData/trendData.constants';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { WORKSHEET_SIDEBAR_TAB } from '@/worksheet/worksheet.constants';
import { currentWorkstepAction } from '@/worksteps/worksteps.actions';
import { exploreAssetSearchActions, initializeSearchActions } from '@/search/search.actions';
import { setCurrentUser } from '@/workbench/workbench.actions';
import { FilterEnum } from '@/sdk/api/FoldersApi';
import { tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { addTrendItem, swapAssets } from '@/trendData/trend.actions';
import { sqStateSynchronizer } from '@/core/core.stores';
import { addWorkbook } from '@/homescreen/homescreen.actions';
import { displayRange, investigateRange } from '@/trendData/duration.actions';

/**
 * Builds a worksheet in a new or existing workbook based on the parameters.
 *
 * @param {BuilderParameters} params - The parameters that are used to build up the worksheet
 * @returns {Promise} Resolves with an object containing workbookId and worksheetId
 */
export function runBuilder(params: BuilderParameters) {
  _.defaults(params, { startFresh: true, viewMode: ViewMode.Edit });

  return setCurrentUser() // Must be first so currentUser is available to other actions
    .then(() => findOrAddWorkbook(params.workbookName, params.workbookFilter))
    .then((workbookId) => findOrAddWorksheet(params.worksheetName, workbookId))
    .then((stateParams) => {
      setWorkbookDisplayMode(params.viewMode === ViewMode.Edit ? WORKBOOK_DISPLAY.EDIT : WORKBOOK_DISPLAY.VIEW);
      return populateWorkstepState(params, stateParams)
        .then(() =>
          params.viewMode === ViewMode.Edit
            ? sqStateSynchronizer.push(PUSH_WORKSTEP_IMMEDIATE, stateParams)
            : undefined,
        )
        .then(_.constant(stateParams));
    });
}

/**
 * Finds an existing workbook by name or id. Creates a new workbook if it does not exist.
 *
 * @param {string} [workbookName] - The workbook name or id
 * @param {string} [filter] - The filter for the workbench query
 * @returns {Promise<string>} A promise that resolves with the workbook id
 */
function findOrAddWorkbook(workbookName, filter = FilterEnum.Mine) {
  if (validateGuid(workbookName)) {
    return Promise.resolve(workbookName);
  } else {
    const name = _.isEmpty(workbookName) ? i18next.t(DEFAULT_WORKBOOK_NAME) : workbookName;
    return sqFoldersApi
      .getFolders({
        textSearch: `"${name}"`,
        types: [API_TYPES.ANALYSIS, API_TYPES.TOPIC],
        filter,
        limit: 1000,
      })
      .then(({ data: { content } }) => _.find(content, { name }))
      .then((workbook) =>
        workbook ? workbook.id : addWorkbook({ name, addNewWorksheet: false }).then(({ workbookId }) => workbookId),
      );
  }
}

/**
 * Finds an existing worksheet by name or id. Creates a new worksheet if it does not exist.
 *
 * @param {String} worksheetName - The worksheet name or id
 * @param {String} workbookId - The workbook id
 * @returns {Promise} A promise that resolves with an object containing workbookId and worksheetId
 */
function findOrAddWorksheet(worksheetName, workbookId) {
  if (validateGuid(worksheetName)) {
    return Promise.resolve({ workbookId, worksheetId: worksheetName });
  } else {
    return getWorksheets(workbookId).then((worksheets) => {
      const worksheetId = _.get(_.find(worksheets, ['name', worksheetName]), 'worksheetId');

      if (worksheetId) {
        return Promise.resolve({ workbookId, worksheetId });
      } else {
        return createWorksheet(workbookId, worksheetName).then(({ worksheetId }) => ({ workbookId, worksheetId }));
      }
    });
  }
}

/**
 * Populates the workstep state based on the builder parameters, optionally starting with the current workstep of
 * the specified worksheet.
 *
 * @param {BuilderParameters} params - The builder parameters
 * @param {Object} stateParams - The workbook and worksheet ids
 */
function populateWorkstepState(params: BuilderParameters, stateParams) {
  let workstepPromise;
  if (params.startFresh) {
    workstepPromise = Promise.resolve();
  } else {
    sqStateSynchronizer.setLoadingWorksheet(stateParams.workbookId, stateParams.worksheetId);
    workstepPromise = currentWorkstepAction(stateParams.workbookId, stateParams.worksheetId)
      .then(({ current: { state } }) =>
        sqStateSynchronizer.rehydrate(state, {
          workbookId: stateParams.workbookId,
          worksheetId: stateParams.worksheetId,
        }),
      )
      .catch(_.noop) // New worksheet, can safely ignore
      .finally(() => {
        sqStateSynchronizer.unsetLoadingWorksheet();
      });
  }

  return workstepPromise.then(() => {
    setInvestigationRange(params.investigateStartTime, params.investigateEndTime);
    setDisplayRange(params.displayStartTime, params.displayEndTime);
    if (params.selectedTab && WORKSHEET_SIDEBAR_TAB[params.selectedTab]) {
      tabsetChangeTab('sidebar', WORKSHEET_SIDEBAR_TAB[params.selectedTab] as string);
    }

    const addItems = addTrendItems(params.trendItems, stateParams.workbookId).then(() =>
      swapAsset(params.assetSwap, stateParams.workbookId),
    );
    return Promise.all([exploreAsset(params.expandedAsset, stateParams.workbookId), addItems]);
  });
}

/**
 * Search for each trend item and add it to the trend on the specified worksheet.
 *
 * @param {string[]} trendItems - An array of item names that will be added to the trend. If the item
 *   exists as part of an asset tree then its location in the Asset tree can be specified using the following
 *   syntax: Full >> Asset >> Path >> Item Name
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} A promise that resolves when all items are added to the trend
 */
function addTrendItems(trendItems, workbookId) {
  const nonAssetItems = _.chain(trendItems)
    .reject((name) => _.includes(name, ASSET_DELIMITER))
    .reject(validateGuid)
    .thru((trendItems) => {
      if (_.isEmpty(trendItems)) {
        return Promise.resolve();
      }

      return sqItemsApi
        .searchItems({
          filters: trendItems,
          types: SEARCH_TYPES,
          limit: SEARCH_PER_PAGE,
          scope: workbookId ? [workbookId] : undefined,
        })
        .then(({ data: { items } }) =>
          _.chain(items)
            .filter(isTrendable)
            .sortBy((item) => _.toLower(item.name))
            .partition((item) => _.includes(trendItems, item.name))
            .flatten()
            .map((item) => addTrendItem(item))
            .thru((p) => Promise.all(p))
            .value(),
        );
    })
    .value();

  const idItems = _.chain(trendItems)
    .filter(validateGuid)
    .map((id) => sqItemsApi.getItemAndAllProperties({ id }).then(({ data: item }) => addTrendItem(item)))
    .value();

  const assetItems = _.chain(trendItems)
    .filter(_.partial(_.includes, _, ASSET_DELIMITER))
    .map((nameWithPath) => {
      const parts = _.chain(nameWithPath).split(ASSET_DELIMITER).map(_.trim).value() as any;
      const assetPath = _.initial(parts);
      const itemName = _.last(parts) as string;
      return getAssetFromPath(assetPath, workbookId)
        .then((asset) => {
          const assetId = _.get(asset, 'id');
          return assetId
            ? sqTreesApi
                .getTree({
                  id: assetId,
                  limit: 10000,
                  scope: _.compact([workbookId]),
                })
                .then(({ data: { children } }) => children)
            : [];
        })
        .then((children) => {
          const item = _.find(children, (child: any) => child.name.toLowerCase() === itemName.toLowerCase());
          if (item) {
            return addTrendItem(item);
          }
        });
    })
    .value();

  return Promise.all(assetItems.concat(nonAssetItems).concat(idItems));
}

/**
 * Finds the specified asset and sets it as the current asset in search.
 *
 * @param {string} expandedAsset - An asset name, id or full path to an asset that will be explored in the search
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} A promise that resolves when the asset is loaded
 */
function exploreAsset(expandedAsset, workbookId) {
  return getAsset(expandedAsset, workbookId).then((asset) =>
    asset
      ? exploreAssetSearchActions('main', asset.id)
      : initializeSearchActions('main', SEARCH_TYPES, false, [workbookId]),
  );
}

/**
 * Finds an asset either by name, id or fully specified path
 *
 * @param {string} assetSpecifier - Name, id or full asset path to an asset
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} that resolves with the asset, or empty if not found
 */
function getAsset(assetSpecifier: string, workbookId: string) {
  if (_.isEmpty(assetSpecifier)) {
    return Promise.resolve();
  } else if (validateGuid(assetSpecifier)) {
    return sqItemsApi.getItemAndAllProperties({ id: assetSpecifier }).then(({ data: item }) => item);
  } else if (_.includes(assetSpecifier, ASSET_DELIMITER)) {
    // Get the asset from the full path provided
    return getAssetFromPath(_.chain(assetSpecifier).split(ASSET_DELIMITER).map(_.trim).value(), workbookId);
  } else {
    // Search for the asset by name only
    return (
      sqItemsApi.searchItems({
        filters: [assetSpecifier],
        limit: 1,
        types: [API_TYPES.ASSET],
        scope: workbookId ? [workbookId] : undefined,
      }) as Promise<any>
    ).then(({ data: { items } }) => _.head(items));
  }
}

/**
 * Returns an asset from its full asset path. Match is by name case-insensitive at each level.
 *
 * @param {string[]} assetLevels - Array of path tokens for an asset
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} that resolves with the asset or undefined if not found
 */
function getAssetFromPath(assetLevels, workbookId) {
  return sqTreesApi
    .getTreeRootNodes({ limit: 10000, scope: _.compact([workbookId]) })
    .then(({ data: { children } }) => {
      const rootAsset = _.find(children, (item: any) => _.toLower(_.head(assetLevels)) === _.toLower(item.name));

      if (!rootAsset) {
        return Promise.resolve();
      }

      return _.reduce(
        _.tail(assetLevels),
        (parentPromise, assetName) => parentPromise.then((parent) => getAssetChild(assetName, parent, workbookId)),
        Promise.resolve(rootAsset),
      );
    });
}

/**
 * Returns a child asset from its name and parent object. Match is case-insensitive.
 *
 * @param {string} assetName - Name of the asset to find
 * @param {Object} parent - Parent asset
 * @param {string} parent.id - ID of the parent asset
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} that resolves with the asset or undefined if not found
 */
function getAssetChild(assetName, parent, workbookId) {
  if (_.isEmpty(assetName) || _.isEmpty(parent)) {
    return Promise.resolve();
  }

  return sqTreesApi
    .getTree({ id: parent.id, limit: 10000, scope: _.compact([workbookId]) })
    .then(({ data: { children } }) =>
      _.find(children, (child: any) => child.name.toLowerCase() === assetName.toLowerCase()),
    );
}

/**
 * Performs an asset swap to show all trend items relative to the provided asset. This assumes that all
 * asset-relative items on the trend are for the same asset. If added items from from more than one asset, then
 * only one of the existing assets will be swapped out; which one is non-deterministic.
 *
 * @param {string} assetSpecifier - Asset to use as the destination of the swap. Can be specified by name, id or
 *   by the full path to the asset, using >> as the path delimiter.
 * @param {string} workbookId - The id of the workbook; used to limit the scope
 * @returns {Promise} that resolves when the asset is found and resolved.
 */
function swapAsset(assetSpecifier, workbookId) {
  const swapOutItemIds = _.chain(getAllItems({})).uniqBy('id').map('id').value();

  return getAsset(assetSpecifier, workbookId)
    .then((asset) =>
      asset
        ? sqItemsApi.getSwapOptions({ id: asset.id, swapOutItemIds })
        : Promise.resolve({ data: {} as SwapOptionListV1 }),
    )
    .then(({ data: { swapOptions } }) => {
      if (!_.isEmpty(swapOptions)) {
        const swapPairs = _.mapValues(_.keyBy(_.first(swapOptions).itemsWithSwapPairs, 'item.id'), 'swapPairs');

        return swapAssets(swapPairs);
      }
    });
}

/**
 * Parses and sets the start and/or end time of a specified range. Start and end are both optional.
 * Each date is a string that can be in either ISO-8601 format or using now-relative notation, i.e. '*' or '*-7d'.
 *
 * @param [startTime=endTime - defaultDuration] - Start date/time
 * @param [endTime=startTime + defaultDuration] - End date/time
 * @param defaultDurationDays - Duration to use if only one date/time is specified, in days
 * @param updateTimes - Function to call with resulting dates
 */
function setRange(startTime: string, endTime: string, defaultDurationDays: number, updateTimes: Function) {
  let startMoment = moment.invalid();
  let endMoment = moment.invalid();
  const now = moment.utc();

  if (startTime) {
    startMoment = parseISODate(startTime);
    if (!startMoment.isValid()) {
      startMoment = parseRelativeDate(startTime, now);
    }
  }

  if (endTime) {
    endMoment = parseISODate(endTime);
    if (!endMoment.isValid()) {
      endMoment = parseRelativeDate(endTime, now);
    }
  }

  if (startMoment.isValid() && !endMoment.isValid()) {
    endMoment = moment.utc(startMoment).add(defaultDurationDays, 'days');
  }

  if (!startMoment.isValid() && endMoment.isValid()) {
    startMoment = moment.utc(endMoment).subtract(defaultDurationDays, 'days');
  }

  if (startMoment.isValid() && endMoment.isValid()) {
    updateTimes(startMoment.valueOf(), endMoment.valueOf());
  }
}

/**
 * Sets the start and/or end time of the Display Range.
 *
 * @param {string} [startTime=endTime - DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - Start date/time
 * @param {string} [endTime=startTime + DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - End date/time
 */
function setDisplayRange(startTime, endTime) {
  setRange(startTime, endTime, DEFAULT_DISPLAY_RANGE_DURATION_DAYS, displayRange.updateTimes);
}

/**
 * Sets the start or end time of the Investigation Range.
 * @param {string} [startTime=endTime - DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - Start date/time
 * @param {string} [endTime=startTime + DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - End date/time
 */
function setInvestigationRange(startTime, endTime) {
  setRange(startTime, endTime, DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS, investigateRange.updateTimes);
}
